<template>
  <div class="header-container">
    <!-- featured image -->
    <img src="" alt="" class="featured-image" />

    <!-- main -->
    <div class="main-container">
      <h1>main</h1>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
